import { Theme } from '@/contexts/ThemeContext';
import { checkIfSubscriptionTheme } from '@/utils/helpers';

const row = ({
  title,
  price,
  index,
}: {
  title: string;
  price: string;
  index: number;
}) => {
  return (
    <tr key={index} className={`${index % 2 === 0 ? 'bg-gray-100' : ''}`}>
      <td className="px-2 py-2">
        <div className="text-xs leading-5 font-normal text-gray-900">
          {title}
        </div>
      </td>
      <td className="px-2 py-2 text-xs leading-5">
        <div className="text-xs leading-5 font-normal text-gray-900 text-right">
          {price}
        </div>
      </td>
    </tr>
  );
};

export default function Charges({ theme }: { theme: Theme }): JSX.Element {
  const isSubscriptionTheme = checkIfSubscriptionTheme(
    theme.slug,
    theme.landingPage
  );
  const rows = !isSubscriptionTheme
    ? [
        {
          title:
            'Please note that £81 of the prices bellow are our service fee and VAT is included.',
          price: '',
        },
        {
          title: 'Provisional Licence',
          price: '£115',
        },
        {
          title: 'Change of Address on Licence',
          price: '£81',
        },
        {
          title: 'Renew your Driver’s Licence',
          price: '£95',
        },
        {
          title: 'Renew your Driver’s Licence (70+)',
          price: '£81',
        },
        {
          title: 'Replacement Licence',
          price: '£101',
        },
        {
          title: 'Exchange paper licence for photo card',
          price: '£101',
        },
      ]
    : [
        {
          title: 'Provisional Licence',
          price: '£34',
        },
        {
          title: 'Change of Address on Licence',
          price: '£0',
        },
        {
          title: 'Renew your Driver’s Licence',
          price: '£14',
        },
        {
          title: 'Renew your Driver’s Licence (70+)',
          price: '£0',
        },
        {
          title: 'Replacement Licence',
          price: '£20',
        },
        {
          title: 'Exchange paper licence for photo card',
          price: '£20',
        },
      ];

  return (
    <div className="flex flex-col max-w-8xl mx-auto mb-8 px-4 sm:px-6 lg:px-8">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full">
              <thead>
                <tr>
                  <th
                    className={`py-2 w-10/12 text-left ${
                      isSubscriptionTheme ? 'text-md' : 'text-sm'
                    } leading-4 font-medium text-gray-900 tracking-wider`}
                  >
                    {isSubscriptionTheme ? 'Government Fees' : 'Our Charges'}
                  </th>
                  <th className="px-2 py-1"></th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {rows.map(({ title, price }, index) => {
                  return row({ title, price, index });
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
