import { Theme } from '@/contexts/ThemeContext';
import Charges from '@/components/layout/Charges';
import { subscriptionPricePeriod } from '@/utils/helpers';
import { useEffect, useState } from 'react';

export default function Features({ theme }: { theme: Theme }): JSX.Element {
  const TITLE = process.env.NEXT_PUBLIC_TITLE;
  const { slug, landingPage } = theme;
  const [MZAdress, setMZAdress] = useState('');

  useEffect(() => {
    if (slug !== null && slug?.includes('dl.')) {
      const domain = slug?.replace('dl', 'member');
      setMZAdress(`https://${domain}`);
    } else {
      setMZAdress(process.env.NEXT_PUBLIC_MZ_ADDRESS);
    }
  }, [slug]);

  return (
    <div className="mb-10 container lg:pr-8 flex flex-col mx-auto py-8 px-6 sm:px-32">
      {slug === 'eyesol' ? (
        <>
          <h3 className="mt-2 text-2xl leading-8 text-center sm:text-left font-bold tracking-tight text-primary">
            What we offer
          </h3>
          <ul className="mt-10">
            <li className="mb-5">
              <h4 className="text-base leading-5 font-medium text-gray-900 font-bold check">
                Admin
              </h4>
              <p className="mt-2 text-sm leading-5 text-gray-900">
                Use our simple step by step solution to apply, renew and replace
                your UK Licence. By choosing our services, your application will
                be thoroughly checked for any mistakes or omissions before being
                processed to{' '}
                <a
                  className="underline"
                  href="https://www.gov.uk/browse/driving/driving-licences"
                  target="_blank"
                  rel="noreferrer"
                >
                  DVLA
                </a>
                . This ensures your application is completed as quickly as
                possible with minimal delay. Please note{' '}
                <strong>
                  {TITLE} is in no way affiliated with the government. You can
                  apply onto the UK Government website or DVLA to benefit from a
                  reduced cost.
                </strong>
              </p>
            </li>
            <li className="mb-5">
              <h4 className="text-base leading-5 font-medium text-gray-900 font-bold check">
                Virtual Assistants
              </h4>
              <p className="mt-2 text-sm leading-5 text-gray-900">
                A multiservice Virtual Assistant will be available to support
                you through any type of administrative procedure in the UK to
                act on your behalf and relieve you.
              </p>
            </li>
            <li className="mb-5">
              <h4 className="text-base leading-5 font-medium text-gray-900 font-bold check">
                Licence Protection
              </h4>
              <p className="mt-2 text-sm leading-5 text-gray-900">
                {TITLE} covers any Licence’s losses or thefts. We offer
                unlimited replacements of your{' '}
                {theme.slug !== 'eyesol' && 'Driver’s'} Licence if you lose it.
                After being informed of your request, we will apply for a new
                {theme.slug !== 'eyesol' && 'Driver’s'} Licence on your behalf;
                DVLA fees are on us.
              </p>
            </li>
            <li className="mb-5">
              <h4 className="text-base leading-5 font-medium text-gray-900 font-bold check">
                Legal
              </h4>
              <p className="mt-2 text-sm leading-5 text-gray-900">
                {TITLE} can help you if you are facing a licence dispute and in
                need to file a lawsuit, or any other legal issue you may
                encounter.
              </p>
            </li>
            <li className="mb-5">
              <h4 className="text-base leading-5 font-medium text-gray-900 font-bold check">
                Customer Support
              </h4>
              <p className="mt-2 text-sm leading-5 text-gray-900">
                Our fees include unlimited access to a highly-available customer
                support that is willing to answer any of your inquiries with
                minimal delay.
              </p>
            </li>
            <li className="mb-5">
              <h4 className="text-base leading-5 font-medium text-gray-900 font-bold check">
                Loyalty
              </h4>
              <p className="mt-2 text-sm leading-5 text-gray-900">
                We want you to make the most out of your income.That’s why we
                have secured discounts to fit all lifestyles. We have partnered
                up with a variety of different retailers to offer you exclusive
                discounts on many car-related products and services.
              </p>
            </li>
            <li className="mb-5">
              <h4 className="text-base leading-5 font-medium text-gray-900 font-bold check">
                Penalty Points
              </h4>
              <p className="mt-2 text-sm leading-5 text-gray-900">
                We offer real-time access to your penalty point. At the end of
                each month, our SMS Messaging Service will contact you to inform
                you of the current status of your Licence&apos;s points.
              </p>
            </li>
          </ul>
        </>
      ) : (
        <div>
          <h3 className="mt-2 text-xl leading-8 font-medium tracking-tight text-green-500">
            Our Services
          </h3>
          <p className="text-sm mt-4 leading-5 text-gray-900 lg:mx-auto">
            Get the support you need for all your driver’s licence applications
            and any admin task in the UK at any time. Forget about admin pain
            and the stress it can cause, we are here to help you with our
            general admin support, legal assistance and loyalty program. 3 days
            free trial then {subscriptionPricePeriod[landingPage]} including all
            the services detailed below:
          </p>
          <ul className="mt-10">
            <li className="mb-5">
              <h4 className="text-base leading-5 font-medium text-gray-900">
                Admin
              </h4>
              <p className="mt-2 text-sm leading-5 text-gray-900">
                Use our simple step-by-step solution to apply, renew and replace
                your UK Driver’s Licence. By choosing our services, your
                application will be thoroughly checked for any mistakes or
                omissions before being processed to DVLA. This ensures that your
                application is completed as quickly as possible with minimal
                delay. Please note we are in no way affiliated with the
                government. You can apply to the UK Government website or DVLA
                to benefit from a reduced cost.
              </p>
            </li>
            <li className="mb-5">
              <h4 className="text-base leading-5 font-medium text-gray-900">
                Legal
              </h4>
              <p className="mt-2 text-sm leading-5 text-gray-900">
                Our team of lawyers will help you if you are facing any dispute,
                need to file a lawsuit or any other basic legal issue you may
                encounter.
              </p>
            </li>
            <li className="mb-5">
              <h4 className="text-base leading-5 font-medium text-gray-900">
                Customer Support
              </h4>
              <p className="mt-2 text-sm leading-5 text-gray-900">
                Our fees include unlimited access to highly-available customer
                support reachable by phone, email and chat. Our team is ready to
                answer any of your inquiries with minimal delay.
              </p>
            </li>
            <li className="mb-5">
              <h4 className="text-base leading-5 font-medium text-gray-900">
                Loyalty
              </h4>
              <p className="mt-2 text-sm leading-5 text-gray-900">
                Exclusive deals and discounts on various products and services.
                We have partnered with retailers all across the UK to offer you
                various exclusive deals.
              </p>
            </li>
            <li className="mb-5">
              <h4 className="text-base leading-5 font-medium text-gray-900">
                Our Pricing
              </h4>
              <p className="mt-2 text-sm leading-5 text-gray-900">
                Using our Services you will be entitled to 3 days Free Trial and
                you will have to pay the Government Fees related to your
                application.
              </p>
            </li>
          </ul>

          <Charges theme={theme} />
        </div>
      )}
      <div className="text-center sm:text-left">
        <a
          className="p-2 rounded-md font-bold hover:bg-black hover:text-white"
          href={MZAdress}
        >
          {'Access your Member Zone >'}
        </a>
      </div>
    </div>
  );
}
